// settings
@import "settings/mixins";
@import "settings/colors";
@import "settings/fonts";
@import "settings/icons";
@import "settings/typography";
@import "settings/variables";
.cpbulba-banner {
    @import "bootstrap/grid";
    position: relative;
    background-image: linear-gradient(60deg, $gradient-color3 0%, $gradient-color3 65%, $gradient-color4 100%);
    color: #fff;
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
    }
    .container{
        padding: 2rem;
        padding-bottom: 12rem;
    }
    .content {
        .heading {
            font-size: 3em;
            line-height: 1.2;
            @include media("tablet-wide", max){
                font-size: 2.25em;
                text-align: center;
            }
        }
        .highlights {
            color: #fff;
            @include media("tablet-wide", min){
                display: flex;
                align-items: center;
            }
            @include media("tablet-wide", max){
                text-align: center;
            }
            i {
                font-size: 4em;
                @include media("tablet-wide", min){
                    margin-right: .2em;
                }
                @include media("tablet-wide", max){
                    display: inline-block;
                    margin-bottom: .2em;
                }
                @include media("phone", max){
                    font-size: 3em;
                }
            }
            .text {
                line-height: 1.2;
                @include media("tablet-wide", max){
                    font-size: .85em;
                }
                h4 {
                    margin-bottom: .25em;
                }
            }
        }
        .buttons {
            display: flex;
            align-items: center;
            @include media("tablet-wide", max){
                justify-content: center;
            }
            &>*{
                &:not(:last-child){
                    margin-right: 1rem;
                }
            }
            .call{
                font-family: $heading-font-family;
                font-weight: $heading-font-weight;
                text-transform: uppercase;
                font-size: .85em;
                padding: .8rem 1.5rem;
                letter-spacing: .15rem;
                border-radius: 2rem;
                background: #fff;
                color: $gradient-color2;
                &:hover{
                    background: $default-color2;
                    color: $gradient-color3;
                    letter-spacing: .25rem;
                }
            }
            .more{
                font-family: $heading-font-family;
                font-weight: $heading-font-weight;
                text-transform: uppercase;
                font-size: .65em;
                letter-spacing: .15rem;
                color: rgba(#fff, .75);
                padding: .5em 0;
                border-bottom: 1px dotted rgba(#fff, .75);
                &:hover{
                    color: $default-color2;
                    border-color: $default-color2;
                    letter-spacing: .25rem;
                }
            }
        }
    }
    .featured{
        img{
            border-radius: .5rem;
        }
    }
    .radius {
        position: absolute;
        bottom: -1px;
        width: 100%;
        overflow: hidden;
        svg {
            height: 35px;
            display: block;
            width: 100%;
            path {
                fill: #fff;
            }
        }
    }
    .bg-slider{
        opacity: .35;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
        user-select: none;
        li {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: none;
            opacity: 0;
            animation: imageAnimation 50s linear infinite 0s;
            &:nth-child(2){
                animation-delay: 10s;
            }
            &:nth-child(3){
                animation-delay: 20s;
            }
            &:nth-child(4){
                animation-delay: 30s;
            }
            &:nth-child(5){
                animation-delay: 40s;
            }
            @keyframes imageAnimation { 
                0% { opacity: 0;
                animation-timing-function: ease-in; }
                8% { opacity: 1;
                     animation-timing-function: ease-out; }
                17% { opacity: 1 }
                25% { opacity: 0 }
                100% { opacity: 0 }
            } 
        }
    }
}